import axios from 'axios';

import sound from '../effect/walk.wav';

const getFoodData = async (lat: number, lng: number) => {
  try {
    const audio = new Audio(sound);
    audio.volume = 0.2;
    audio.play();

    const { data } = await axios({
      method: 'GET',
      url: '/main-service/food',
      params: {
        lat,
        lng,
      },
      // withCredentials: true,
    });

    // console.log(data);
    return data;

    // return response.data;
  } catch (e) {
    console.log(e);
  }
};

export default getFoodData;
