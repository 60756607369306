import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import './styles/minireset.min.css';
import './styles/fonts.css';
import axios from 'axios';

// if (process.env.NODE_ENV === 'development') {
//   (async () => {
//     const { worker } = await import('./mocks/browser');
//     worker.start();
//   })();
// }

// axios.defaults.baseURL = 'https://jeonwoochi.shop/api';
// axios.defaults.baseURL = 'http://localhost:8000/api';
// axios.defaults.baseURL = "http://localhost:8080";
axios.defaults.baseURL = 'https://api.jeonwoochi-m.shop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);
